import React, { useState, useEffect } from 'react';
import s from './Search.module.scss';

const hideFactory = (cb: Function) => (e: Event) => {
  if (
    e.target &&
    // @ts-ignore
    (e.target.closest(`.${s.MockOption}`) ||
      // @ts-ignore
      e.target.closest(`.${s.MockSelect}`))
  )
    return;
  cb();
};

const MockSelect = ({
  children,
  style = {},
}: {
  children: any;
  style?: { zIndex?: number };
}) => {
  const [active, setActive] = useState(false);
  const hide = hideFactory(() => {
    setActive(false);
  });
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 27) setActive(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', hide);
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('click', hide);
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <span
      onClick={() => setActive(!active)}
      className={s.MockSelect + (active ? ' ' + s.MockSelectActive : '')}
      style={Object.assign({ overflow: 'hidden' }, style)}
    >
      {children}
    </span>
  );
};

export default MockSelect;
