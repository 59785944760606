import React from 'react';
import { uniq } from 'lodash';
import { Field } from 'formik';
import {
  DS_SUB_TYPES,
  ALT_SUB_TYPES,
  AD_SUB_TYPES,
  ProgramTypes,
  ProgramTypeFilters,
} from '../../utils/search/constants';
import MockSelect from './MockSelect';
import s from './Search.module.scss';
import IconSearch from '../../svgs/ico_search.svg';

export const MockOptionsType = ({
  options,
  programTypeFilters,
  setProgramTypeFilters,
  type,
}: {
  options: string[];
  programTypeFilters: ProgramTypeFilters;
  setProgramTypeFilters: React.Dispatch<
    React.SetStateAction<ProgramTypeFilters>
  >;
  type: ProgramTypes;
}) => {
  const st = programTypeFilters.programSubType;
  const pt = programTypeFilters.programType;

  // no subtypes yet selected = fresh
  const fresh = st.filter(s => options.includes(s)).length === 0;

  const viewingAll =
    st.filter(s => options.includes(s)).length === options.length;

  return (
    <div className={s.MockOptions}>
      <div
        className={s.MockOption}
        onClick={e => {
          e.stopPropagation();
          setProgramTypeFilters({
            programSubType: viewingAll
              ? st.filter(s => !options.includes(s))
              : uniq(st.concat(options)),
            programType: viewingAll
              ? pt.filter(p => p !== type)
              : uniq(pt.concat(type)),
          });
        }}
      >
        <Field type="checkbox" checked={viewingAll} />
        View All
      </div>
      {options.map((name: string) => {
        const checked = st.includes(name);
        // if this subtype is checked and we're unchecking it,
        // and if it's the last subtype around, then it's going byebye
        const byebye = checked && st.length === 1;
        return (
          <div key={name}>
            <div
              className={s.MockOption}
              onClick={e => {
                e.stopPropagation();
                setProgramTypeFilters({
                  programSubType: checked
                    ? st.filter(a => a !== name)
                    : st.concat(name),
                  programType: fresh
                    ? pt.concat(type)
                    : byebye
                    ? pt.filter(a => a !== type)
                    : pt,
                });
              }}
            >
              <Field type="checkbox" checked={checked} />
              {name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SubtypesSearch = ({
  programTypeFilters,
  setProgramTypeFilters,
  update,
  hide,
}: {
  programTypeFilters: ProgramTypeFilters;
  setProgramTypeFilters: React.Dispatch<
    React.SetStateAction<ProgramTypeFilters>
  >;
  handleShowResults: any;
  update: () => void;
  hide: () => void;
}) => {
  const selectedAlt = programTypeFilters.programSubType.filter(s =>
    Object.values(ALT_SUB_TYPES.filterOptions).includes(s)
  ).length;
  const selectedAdvo = programTypeFilters.programSubType.filter(s =>
    Object.values(AD_SUB_TYPES.filterOptions).includes(s)
  ).length;
  const selectedDirect = programTypeFilters.programSubType.filter(s =>
    Object.values(DS_SUB_TYPES.filterOptions).includes(s)
  ).length;

  return (
    <>
      <div>
        <div>
          <MockSelect>
            Alternatives to Incarceration{' '}
            {selectedAlt > 0 && `(${selectedAlt})`}
          </MockSelect>
          <MockOptionsType
            programTypeFilters={programTypeFilters}
            setProgramTypeFilters={setProgramTypeFilters}
            options={Object.values(ALT_SUB_TYPES.filterOptions)}
            type={ProgramTypes.ALT}
          />
        </div>
      </div>
      <div>
        <div>
          <MockSelect>
            Advocacy {selectedAdvo > 0 && `(${selectedAdvo})`}
          </MockSelect>
          <MockOptionsType
            programTypeFilters={programTypeFilters}
            setProgramTypeFilters={setProgramTypeFilters}
            options={Object.values(AD_SUB_TYPES.filterOptions)}
            type={ProgramTypes.AD}
          />
        </div>
      </div>
      <div>
        <div>
          <MockSelect>
            Direct Services for Incarcerated or Formerly Incarcerated{' '}
            {selectedDirect > 0 && `(${selectedDirect})`}
          </MockSelect>
          <MockOptionsType
            programTypeFilters={programTypeFilters}
            setProgramTypeFilters={setProgramTypeFilters}
            options={Object.values(DS_SUB_TYPES.filterOptions)}
            type={ProgramTypes.DS}
          />
        </div>
      </div>
      <button
        className={s.ApplyFilters}
        onClick={e => {
          e.preventDefault();
          update();
          hide();
        }}
        style={{ borderWidth: 0 }}
      >
        {programTypeFilters.programSubType.length === 0
          ? 'Show all'
          : 'Apply filters'}
        <IconSearch
          width={16}
          height={16}
          style={{
            marginLeft: 12,
            marginRight: -8,
            stroke: '#fff',
          }}
        />
      </button>
    </>
  );
};

export default SubtypesSearch;
