import React from 'react';
import s from './SearchFormTab.module.scss';

export default ({
  active = false,
  noBorder = false,
  noHover = false,
  children,
  onClick,
  style,
}: {
  active?: boolean;
  noBorder?: boolean;
  noHover?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => void;
}) => (
  <button
    className={
      s.SearchFormTab +
      (active ? ' ' + s.SearchFormTabActive : '') +
      ' ' +
      (noHover ? s.SearchFormTabNoHover : '') +
      ' ' +
      (noBorder ? s.SearchFormTabNoBorder : '')
    }
    style={style}
    onClick={onClick}
  >
    {children}
  </button>
);
