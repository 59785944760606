import { IMapPin } from '../interfaces/map-pin.interface';

export const MAP_PINS: IMapPin[] = [
  {
    name: 'United States of America',
    lat: 38.627750396728516,
    lng: -90.1995620727539,
  },
  {
    name: 'United Kingdom',
    lat: 53.943836212,
    lng: -2.550564051,
  },
  {
    name: 'Costa Rica',
    lat: 9.974835396,
    lng: -84.193855286,
  },
  {
    name: 'Australia',
    lat: -25.578937531,
    lng: 134.359710693,
  },
  {
    name: 'Argentina',
    lat: -38.416096,
    lng: -63.616673,
  },
  {
    name: 'Bangladesh',
    lat: 23.684994,
    lng: 90.356331,
  },
  { name: 'Belgium', lat: 50.503887, lng: 4.469936 },
  {
    name: 'Belize',
    lat: 17.189877,
    lng: -88.49765,
  },
  {
    name: 'Benin',
    lat: 9.30769,
    lng: 2.315834,
  },
  {
    name: 'Bolivia',
    lat: -16.290154,
    lng: -63.588654,
  },
  {
    name: 'Brazil',
    lat: -14.235004,
    lng: -51.925282,
  },
  {
    name: 'Cambodia',
    lat: 12.565679,
    lng: 104.990967,
  },
  {
    name: 'Cameroon',
    lat: 7.369722,
    lng: 12.354722,
  },
  {
    name: 'Canada',
    lat: 56.130367,
    lng: -106.346771,
  },
  {
    name: 'Czech Republic',
    lat: 49.817493,
    lng: 15.472962,
  },
  {
    name: 'Croatia',
    lat: 45.099998,
    lng: 15.2,
  },
  {
    name: 'Chile',
    lat: -35.675148,
    lng: -71.542969,
  },
  {
    name: 'China',
    lat: 35.86166,
    lng: 104.195396,
  },
  {
    name: 'Colombia',
    lat: 4.570868,
    lng: -74.297333,
  },
  {
    name: 'Denmark',
    lat: 56.26392,
    lng: 9.501785,
  },
  {
    name: 'Dominican Republic',
    lat: 18.735693,
    lng: -70.162651,
  },
  {
    name: 'Egypt',
    lat: 26.820553,
    lng: 30.802498,
  },
  {
    name: 'Ethiopia',
    lat: 9.03314,
    lng: 38.75008,
  },
  {
    name: 'El Salvador',
    lat: 13.794185,
    lng: -88.89653,
  },
  {
    name: 'Europe',
    lat: 54.525963,
    lng: 15.255119,
  },
  {
    name: 'Finland',
    lat: 61.92411,
    lng: 25.748152,
  },
  {
    name: 'France',
    lat: 46.227638,
    lng: 2.213749,
  },
  {
    name: 'Gabon',
    lat: -0.803689,
    lng: 11.609444,
  },
  { name: 'Georgia', lat: 41.716667, lng: 44.783333 },
  {
    name: 'Germany',
    lat: -82.900078,
    lng: 10.451526,
  },
  {
    name: 'Ghana',
    lat: 7.946527,
    lng: -1.023194,
  },
  {
    name: 'Greenland',
    lat: 71.70694,
    lng: -42.604301,
  },
  {
    name: 'Hong Kong',
    lat: 22.302711,
    lng: 114.109497,
  },
  {
    name: 'Hungary',
    lat: 47.497913,
    lng: 19.040236,
  },
  { name: 'India', lat: 22.493118286, lng: 79.727012634 },
  { name: 'Indonesia', lat: -0.789275, lng: 113.921326 },
  { name: 'Italy', lat: 41.8719, lng: 12.5674 },
  { name: 'Jamaica', lat: 18.1096, lng: -77.2975 },
  { name: 'Japan', lat: 36.2048, lng: 138.2529 },
  { name: 'Jordan', lat: 30.585163, lng: 36.238415 },
  { name: 'Kenya', lat: -0.023559, lng: 37.906193 },
  { name: 'Kyrgyzstan', lat: 41.20438, lng: 74.766098 },
  { name: 'Latin America', lat: -4.442038, lng: -61.326855 },
  { name: 'Lebanon', lat: 33.854721, lng: 35.862286 },
  {
    name: 'Lesotho',
    lat: -29.609987,
    lng: 28.233608,
  },
  {
    name: 'Pakistan',
    lat: 30.37532,
    lng: 69.345116,
  },
  {
    name: 'Palestine',
    lat: 31.952162,
    lng: 35.233154,
  },
  {
    name: 'Panama',
    lat: 8.537981,
    lng: -80.782127,
  },
  {
    name: 'Philippines',
    lat: 14.5733,
    lng: 120.9833,
  },
  {
    name: 'Poland',
    lat: 51.919437,
    lng: 19.145136,
  },
  {
    name: 'Puerto Rico',
    lat: 18.220833,
    lng: -66.590149,
  },
  {
    name: 'Peru',
    lat: -9.189967,
    lng: -75.015152,
  },
  {
    name: 'Malawi',
    lat: -13.254308,
    lng: 34.301525,
  },
  {
    name: 'Mexico',
    lat: 23.634501,
    lng: -102.552788,
  },
  {
    name: 'Moldova',
    lat: 47.411633,
    lng: 47.411633,
  },
  {
    name: 'Morocco',
    lat: 31.791702,
    lng: -7.09262,
  },
  {
    name: 'Myanmar',
    lat: 21.916222,
    lng: 95.955971,
  },
  {
    name: 'Netherlands',
    lat: 52.132633,
    lng: 5.291266,
  },
  {
    name: 'New Zealand',
    lat: -40.900558,
    lng: 174.885971,
  },
  {
    name: 'Nigeria',
    lat: 9.081999,
    lng: 8.675277,
  },
  {
    name: 'Northern Ireland',
    lat: 54.787716,
    lng: -6.492314,
  },
  { name: 'Norway', lat: 60.472023, lng: 8.468946 },
  {
    name: 'Republic of Cyprus',
    lat: 53.89769,
    lng: 27.54942,
  },
  {
    name: 'Republic of Ireland',
    lat: 53.41291,
    lng: -8.24389,
  },
  {
    name: 'Romania',
    lat: 45.943161,
    lng: 24.966761,
  },
  {
    name: 'Scotland',
    lat: 56.490669,
    lng: -4.202646,
  },
  {
    name: 'Sierra Leone',
    lat: 8.460555,
    lng: -11.779889,
  },
  { name: 'Singapore', lat: 1.352083, lng: 103.819839 },
  {
    name: 'South Africa',
    lat: -30.559483,
    lng: 22.937506,
  },
  {
    name: 'South Korea',
    lat: 35.907757,
    lng: 127.766922,
  },
  {
    name: 'Southern Africa',
    lat: -28.4792625,
    lng: 24.6727135,
  },
  {
    name: 'Spain',
    lat: 40.2085,
    lng: -3.713,
  },
  {
    name: 'Sweden',
    lat: 62.1983366,
    lng: 17.5671981,
  },
  {
    name: 'Tanzania',
    lat: -6.3728253,
    lng: 34.8924826,
  },
  {
    name: 'Tajikistan',
    lat: 38.8581784,
    lng: 71.2479841,
  },
  {
    name: 'Thailand',
    lat: 13.03887,
    lng: 101.490104,
  },
  {
    name: 'Trinidad and Tobago',
    lat: 10.4437128,
    lng: -61.4191414,
  },
  {
    name: 'Tunisia',
    lat: 33.7931605,
    lng: 9.5607653,
  },
  {
    name: 'Turkey',
    lat: 38.9573415,
    lng: 35.240741,
  },
  {
    name: 'Uganda',
    lat: 1.3707295,
    lng: 32.3032414,
  },
  {
    name: 'Uruguay',
    lat: -34.901112,
    lng: -56.164532,
  },
  {
    name: 'Venezuela',
    lat: 10.48801,
    lng: -66.87919,
  },
  {
    name: 'Yemen',
    lat: 15.5539046,
    lng: 48.1748476,
  },
  {
    name: 'Zambia',
    lat: -13.1403507,
    lng: 27.8493049,
  },
  {
    name: 'Zimbabwe',
    lat: -19.0169211,
    lng: 29.1528018,
  },
];
