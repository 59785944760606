import React from 'react';
import s from './PageMenu.module.scss';
import { PageMenuItem } from '../../interfaces/page-menu-item.interface';
import * as Scroll from 'react-scroll';

const PageMenu = ({
  headerHidden,
  menuItems,
  offset,
  onScrollEnd,
  yellow,
}: {
  headerHidden?: boolean;
  menuItems: PageMenuItem[];
  offset?: number;
  onScrollEnd?: () => void;
  yellow?: boolean;
}) => {
  let Link = Scroll.Link;
  let Events = Scroll.Events;
  React.useEffect(() => {
    Events.scrollEvent.register('end', function() {
      onScrollEnd && onScrollEnd();
      // console.log('end', arguments);
    });

    return () => Events.scrollEvent.remove('end');
  }, []);
  offset = offset ? offset : -250;
  return (
    <div
      className={
        s.PageMenu +
        ' ' +
        (yellow ? s.PageMenuYellow : '') +
        ' ' +
        (headerHidden ? s.PageMenuHeaderHidden : '')
      }
    >
      <div className={s.PageMenu__item}>
        {menuItems.map((item, i) => (
          <Link
            key={i}
            activeClass={s.active}
            to={item.link}
            spy={true}
            smooth={true}
            offset={offset}
            duration={500}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // display: 'block',
              }}
            >
              {item.displayText}{' '}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PageMenu;
