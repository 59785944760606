import React from 'react';
import MockSelect from './MockSelect';
import { MockOptions } from './DeepDiveNew';
import s from './Search.module.scss';
import IconSearch from '../../svgs/ico_search.svg';
import { Countries } from '../../utils/search/constants';

export default ({
  countries,
  setCountries,
  update,
  hide,
}: {
  countries: string[];
  setCountries: React.Dispatch<React.SetStateAction<string[]>>;
  update: () => void;
  hide: () => void;
}) => {
  return (
    <>
      <div className={s.CountrySelect}>
        <div>
          <MockSelect>
            Select a country {countries.length > 0 && `(${countries.length})`}
          </MockSelect>
          <MockOptions
            selected={countries}
            onChange={setCountries}
            // @ts-ignore
            options={Object.values(Countries)}
          />
        </div>
      </div>
      <button
        className={s.ApplyFilters + ' ' + s.ApplyFiltersNoMarginTop}
        onClick={e => {
          e.preventDefault();
          update();
          hide();
        }}
        style={{ borderWidth: 0 }}
      >
        Search by Country
        <IconSearch
          width={16}
          height={16}
          style={{
            marginLeft: 12,
            marginRight: -8,
            stroke: '#fff',
          }}
        />
      </button>
    </>
  );
};
