import * as React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/ui/Seo';

import { IProgramCard } from '../components/interfaces/program-card.interface';
import SearchPageTemplate from '../templates/SearchPageTemplate';

const IndexPage = ({
  data: {
    allContentfulProgram: { nodes },
  },
}: {
  data: {
    allContentfulProgram: {
      nodes: IProgramCard[];
    };
  };
}) => {
  let programs: IProgramCard[] = nodes.map((program: any) => {
    let sluggedProgram: IProgramCard = {} as IProgramCard;
    if (program.programName) {
      let slug = program.programName.replace(/\s/g, '');
      sluggedProgram = program;
      sluggedProgram.slug = slug;
    }
    return sluggedProgram;
  });

  // Remove Duplicates
  let flags: any = [],
    uniquePrograms = [],
    l = programs.length,
    i;
  for (i = 0; i < l; i++) {
    if (flags[programs[i].contentful_id]) {
      continue;
    }
    flags[programs[i].contentful_id] = true;
    uniquePrograms.push(programs[i]);
  }

  return (
    <>
      <SEO title="Inn" />
      <SearchPageTemplate />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allContentfulProgram {
      totalCount
      nodes {
        contentful_id
        programType
        programName
        imageLinks
        programSubType
        countryRegion
        website
      }
    }
  }
`;
