import React, { ReactNode } from 'react';
import Header from '../Header/Header';
import SEO from '../Seo';
import WindowDimensionsProvider from '../../hooks/WindowDimensionsProvider';
import PageMenu from '../PageMenu/PageMenu';
import { PageMenuItem } from '../../interfaces/page-menu-item.interface';
import ResponsiveLayout from '../ResponsiveLayout/ResponsiveLayout';

const LandingLayout = ({
  children,
  menuItems,
}: {
  children: ReactNode;
  menuItems?: PageMenuItem[];
}) => {
  return (
    <WindowDimensionsProvider>
      <SEO />
      <div id="root">
        <Header />
        {menuItems && (
          <ResponsiveLayout
            breakpoint={1024}
            renderMobile={null}
            renderDesktop={<PageMenu menuItems={menuItems} offset={-300} />}
          />
        )}

        {children}

        <footer id="footer">
          <p>
            &copy; {new Date().getFullYear()} Incarceration Nations Network. All
            Rights Reserved.
          </p>
        </footer>
      </div>
    </WindowDimensionsProvider>
  );
};

export default LandingLayout;
