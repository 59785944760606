import * as contentful from 'contentful';
import { ICheckboxConfig } from '../../interfaces/checkbox-config.interface';

// Contentful Search
export const SEARCH_LIMIT = '50';
export const CONTENTFUL_CLIENT = contentful.createClient({
  space: `fhwlk5jsnns3`,
  accessToken: `BWNCSsJfya6DdRbu0awNuhieU6E_8ASUgSea0nuIEc4`,
  environment: `staging`,
});

export enum QueryParams {
  typeOfActivity = 'fields.typeOfActivity[in]',
  advocacyTargetGroups = 'fields.advocacyTargetGroups[in]',
  areasOfPolicyInfluence = 'fields.areasOfPolicyInfluence[in]',
  countryRegionMatch = 'fields.countryRegion[match]',
  countryRegionIn = 'fields.countryRegion[in]',
  evaluationConducted = 'fields.evaluationConducted',
  longTermChange = 'fields.longTermChange[in]',
  longTermOutcomes = 'fields.longTermOutcomes[in]',
  mainBeneficiaries = 'fields.mainBeneficiaries[in]',
  query = 'query',
  programSubType = 'fields.programSubType[in]',
  programType = 'fields.programType[in]',
  services = 'fields.services[in]',
  targetGroups = 'fields.targetGroups[in]',
  typeOfEvaluationsConducted = 'fields.typeOfEvaluationsConducted[in]',
}

export enum FilterSet {
  DD = 'DeepDive',
  ST = 'Sub-Type',
  CT = 'Country',
}

// All filters
// *The big picture
// xProblem addressed:
// xProgram objective:
// *Beneficiaries/Target Groups
// xMain Beneficiaries
// XTarget Groups
// *Program Strategies/Targets
// Areas of Policy Influences
// Advocacy Target Groups
// Services
// *Theory of Change
// Activities/Program focus:
// long term change
// long term outcomes
// *Evaluating Success
// xevaluation conducted
// xtype of evaluation conducte
export const PROGRAM_NAME: ICheckboxConfig = {
  filterName: 'programName',
  displayName: 'Program Name',
  filterOptions: [''],
};

export enum ProgramTypes {
  DS = 'Direct Service',
  AD = 'Advocacy',
  ALT = 'Alternative to Incarceration',
}
export const PROGRAM_TYPES: ICheckboxConfig = {
  filterName: 'programType',
  displayName: 'Program type',
  filterOptions: ProgramTypes,
};
export enum ProgramSubTypes {
  POLICY = 'Broad-Reaching Policy Reform / Information Spreading / Policy Research',
  DIVERSION = 'Diversion',
  ECONOMIC = 'Economic/Job Development Behind Bars',
  EDUCATION = 'Education Behind Bars',
  REENTRY = 'Reentry',
  OTHER = 'Other Rehabilitative Services Behind Bars',
  MEDIA = 'Justice-Focused Media',
  STAFF_TRAINING = 'Prison Staff Training',
  LEGAL = 'Legal and/or Bail Assistance',
  LEGAL_ADVOCACY = 'Legal Assistance and Advocacy/Reform',
  TECHNOLOGY = 'Technology for Justice',
  FAMILY_SERVICES = 'Services For Families of the Incarcerated',
  MEDIATION = 'Mediation',
  HEALTH = 'Health and Safety Behind Bars',
  ALTERNATIVES = 'Innovative Alternatives to Traditional Prisons',
  RJ = 'Restorative Justice',
  WATCHDOG = 'Watchdog Organizations',
  LEGAL_REFORM = 'Legal Advocacy/Reform',
}

export interface ProgramTypeFilters {
  programSubType: string[];
  programType: ProgramTypes[];
}

export const PROGRAM_SUB_TYPES: ICheckboxConfig = {
  filterName: 'programSubType',
  displayName: 'Program Sub-type',
  filterOptions: ProgramSubTypes,
};

export enum AdvocacySubTypes {
  POLICY = 'Broad-Reaching Policy Reform / Information Spreading / Policy Research',
  LEGAL_REFORM = 'Legal Advocacy/Reform',
  LEGAL_ADVOCACY = 'Legal Assistance and Advocacy/Reform',
  FAMILY_SERVICES = 'Services For Families of the Incarcerated',
  HEALTH = 'Health and Safety Behind Bars',
  WATCHDOG = 'Watchdog Organizations',
  MEDIA = 'Justice-Focused Media',
}

export const AD_SUB_TYPES: ICheckboxConfig = {
  filterName: 'programSubType',
  displayName: 'Advocacy Sub-Categories',
  filterOptions: AdvocacySubTypes,
};

export enum AlternativesSubTypes {
  RJ = 'Restorative Justice',
  DIVERSION = 'Diversion',
  MEDIATION = 'Mediation',
}

export const ALT_SUB_TYPES: ICheckboxConfig = {
  filterName: 'programSubType',
  displayName: 'Alt.to Incarceration Sub-Categories',
  filterOptions: AlternativesSubTypes,
};

export enum DirectServicesSubTypes {
  ALTERNATIVES = 'Innovative Alternatives to Traditional Prisons',
  FAMILY_SERVICES = 'Services For Families of the Incarcerated',
  EDUCATION = 'Education Behind Bars',
  OTHER = 'Other Rehabilitative Services Behind Bars',
  REENTRY = 'Reentry',
  ECONOMIC = 'Economic/Job Development Behind Bars',
  LEGAL = 'Legal and/or Bail Assistance',
  STAFF_TRAINING = 'Prison Staff Training',
  TECHNOLOGY = 'Technology for Justice',
  HEALTH = 'Health and Safety Behind Bars',
}

export const DS_SUB_TYPES: ICheckboxConfig = {
  filterName: 'programSubType',
  displayName: 'Direct Services Sub-Categories',
  filterOptions: DirectServicesSubTypes,
};

export enum MainBeneficiaries {
  GEN_POP = 'General population in prison',
  HIGH_RISK = 'High-risk or high-security population in prison',
  LOW_RISK = 'Low-risk or low-security population in prison',
  NON_CUST = 'People serving a non-custodial (alternative) sentence',
  CONTACT = 'People with some contact with police but no charge',
  VICTIM = 'People who are involved with the justice system as a victim',
  PRE_TRIAL = 'Pre-trial detainees',
  PAROLE = 'Parole',
  PROBATION = 'Probation',
  VIOLENT = 'People who have committed violent felonies in the adult courts',
  PRE_RELEASE = 'People on pre-release status',
  POST_RELEASE = 'People post-release (reentry)',
  FAMILIES = 'Families of incarcerated people',
  COMMUNITIES = 'Communities affected by incarceration',
  SURVIVORS = 'Survivors and/or their families',
  INSIDE_DRUGS = 'People who use drugs inside prisons',
  OUTSIDE_DRUGS = 'People who use drugs outside of prisons',
  CORRECTIONS = 'Corrections officers or staff',
  ANOTHER_ROLE = 'People who are involved with the justice system in another role',
  LOCAL_COMMUNITY = 'General local community population',
  GENERAL_PUBLIC = 'General public',
}

export const MAIN_BENEFICIARIES: ICheckboxConfig = {
  filterName: 'mainBeneficiaries',
  displayName: 'Main Beneficiaries',
  filterOptions: MainBeneficiaries,
};

export enum TargetGroups {
  YOUTH = 'Juveniles / Youth',
  WOMEN = 'Women',
  LGBTQ = 'LGBTQ people',
  FAMILIES = 'Families of the incarcerated',
  ELDERLY = 'Elderly people',
  GANG = 'Gang-affiliated people',
  OVER_REP = 'Overrepresented groups',
}

export const TARGET_GROUPS: ICheckboxConfig = {
  filterName: 'targetGroups',
  displayName: 'Target Groups',
  filterOptions: TargetGroups,
};

export enum Services {
  LEGAL = 'Legal aid services',
  MENTAL = 'Mental health, counseling services and clinical psychological treatment',
  HEATLH = 'Health and medical services',
  EDUCATION = 'Education programs',
  JOB = 'Job or vocational training programs',
  ADDICTIONS = 'Addictions treatment programs',
  SPORTS = 'Recreation or sports programs',
  RELIGOUS = 'Religious programs',
  ARTS = 'Arts and cultural programs',
  CONFLICT = 'Conflict resolution or mediation services',
  RJ = 'Restorative justice programs',
  PRISON_STAFF = 'Prison staff capacity or accountability',
  DIVERSION = 'Diversion programs',
  SERVICE = 'Community service programs (for alternative sentences)',
  REINT = 'Reintegration',
}

export const SERVICES = {
  filterName: 'services',
  displayName: 'Services',
  filterOptions: Services,
};

export enum EvaluationConducted {
  PERCEPTIONS = 'Participant perceptions (e.g. a survey)',
  PARTICIPATORY = 'Participatory evaluation methods',
  PERCEPTION_ANALYSIS = 'Analysis of changes in perceptions using community data (e.g. survey)',
  OTHER_ANALYSIS = 'Analysis of changes in other data (e.g. crime rates, census data)',
  COMPARE = 'Comparing outcomes pre- and post-intervention for participants',
  COMPARE_RANDOMIZED = 'Comparing outcomes pre- and post-intervention Randomized Control Trials',
  COMPARE_QUASI = 'Comparing outcomes pre- and post-intervention Non-Random Selection',
}

export const EVALUATION_CONDUCTED = {
  filterName: 'evaluationConducted',
  displayName: 'Evaluations Conducted',
  filterOptions: EvaluationConducted,
};

export enum LongTermChange {
  TREATMENT = 'Prisons offer quality, robust rehabilitative treatment programs',
  EDUCATION = 'All interested and eligible students are able to access and range of post-secondary education opportunities regardless of their status with justice systems',
  CONSIDER_FAMILY = 'Children and families are considered by the justice/prison system and can live healthy, active lives free from stigma and disadvantage.',
  HUMAN_RIGHTS = 'Human rights, legal rights, the rule of law, and equal access to justice are protected, respected and upheld for all',
  JAILS_ABOLISHED = 'Jails are dismantled or abolished',
  SUCCESSFUL_REENTRY = 'People released from prison have full support for successful reentry and reintegration',
  DESIGN = 'Prison infrastructure design and material conditions are dignified and decent',
  INTERACTIONS = 'Prison management practices and staff-prisoner interactions uphold human rights principles and standards',
  REDUCED_POP = 'Prison populations are drastically reduced',
  GROUP_REDUCED_POP = 'Prison populations are drastically reduced for a specific group',
  PRISONS_ABOLISHED = 'Prisons are dismantled or abolished',
  SEVERE_ONLY = 'Prisons are reserved for a small proportion of people, for severe crimes only',
  RJ = 'Restorative justice is the principal response to crimes or convictions',
  WELLBEING = 'Social and emotional wellbeing for all is at the heart of rehabilitation',
  EMPOWERED_STAFF = 'Staff are empowered to drive interventions and activities that reduce rates of reoffending.',
  INDIGENOUS = 'Indigenous people have more access to conditional release, and are able to serve their sentences in healing centers run by the Indigenous community.',
  CHILDREN = 'Ensuring that where children or young persons commit offences, they are held accountable and encouraged to accept responsibility for their behavior; and they are dealt with in a way that acknowledges their needs and that will give them the opportunity to develop in responsible, beneficial, and socially acceptable ways.',
  ALT_SERNTANCING = 'Alternative sentencing is the principal response to crimes or convictions',
  PRE_TRIAL = 'The rational use of pre-trail detention, based on procedural risks',
}

export const LONG_TERM_CHANGE = {
  filterName: 'longTermChange',
  displayName: 'Long-term change',
  filterOptions: LongTermChange,
};

export enum LongTermOutcomes {
  CLOSED = 'A significant number of prison facilities are closed or dismantled',
  Criteria = 'Criteria for incarceration – or severity of incarceration – are substantively narrower',
  RJ = 'Institution-wide implementation of restorative justice practices, for people with custodial and non-custodial sentences',
  ALT = 'A significant number of cases with a conviction receive an alternative (non-custodial) sentence',
  NARROW_CRITERIA = 'Institution-wide implementation of narrower criteria for admission to incarceration and broader criteria for reduced sentence or early release (e.g. parole, clemency, compassionate release, other categories)',
  DIVERSION = 'Institution-wide implementation of policies that divert a specific group (e.g. mentally ill, juveniles, elderly, BIPOC) away from custodial sentences',
  AMENITIES = 'Institution-wide implementation of new facilities (partial or full) and amenities',
  MANAGEMENT_POLICIES = 'Institution-wide implementation of management policies to improve dignity, transparency, respect and/or to reduce solitary confinement, severe conditions, etc.',
  REENTRY = 'Institution-wide implementation of reentry planning and resources',
  REHAB = 'Institution-wide implementation of rehabilitative treatment programs',
  OVERTURN_DS = 'Death sentences are overturned, unnecessary imprisonments are avoided, unjustly convicted inmates are released, poor communities are protected and served by the law',
  PATHWAYS_OUT = 'That genuine pathways OUT of the criminal justice system and into the community are established for all incarcerated people, and all people at risk of incarceration.',
  SKILLS = 'Support for formerly incarcerated individuals to undergo skills upgrading while at work.',
  FAMILIES = 'Children and families affected by imprisonment so they can live healthy, active lives free from stigma and disadvantage.',
  NUTURING = 'Everyone has a warm, safe, and nurturing place to live.',
  TRAINING = 'All prisoners and prison staff are provided with training to obtain a basic understanding of conflict management and effective communication to reduce prison violence',
  STRENGHTEN = 'Strengthen the accompaniment processes, incorporating improvements in the methodologies and intervention strategies according to the process developed by families, children and adolescents of the Program and family reengagement processes. (The process of reengagement is associated with accompaniment processes where the significant adult leaves the penitentiary center and joins the family group of the children and adolescents participating in the program)',
  LEGAL = 'Legal framework for broader access to personal bankruptcy',
  ALL = 'All the above and previous answers depend on and inter-relate with a much broader and wider public policy approach to social justice and cannot be taken in isolation from education, health, housing, welfare, community and economic rationale.',
  EMPOWERED_STAFF = 'Staff are empowered to drive interventions and activities that reduce rates of reoffending.',
  AWARENESS = 'There is: a) awareness of the need to give second chances to formerly incarcerated (b) acceptance of the formerly incarcerated and their families in the community, and (c) community action to support the rehabilitation and reintegration of former prisoners.',
  COUNSELLING = 'Initiate the provision od psychosocial counselling to inmates',
  ABORIGINAL = 'Aboriginal controlled and culturally based justice alternatives',
  REDUCE_REC = 'Significant reduction of recidivism',
  JUSTICE_REINVESTMENT = "State-wide implementation of justice reinvestment framework - funding and support for pilots and policy and legislative reforms aimed at reducing the number of Aboriginal and Torres Strait Islander people in prisons and a funding model to ensure 'savings' are reinvested in communities",
  IMPLEMENT_LAWS = 'Effective implementation of existing EU laws on fair trial rights',
  CHILDREN = 'Same as below. That the situation of children with incarcerated is made visible and taken into account when decisions about their parents are made.',
  ACCESS = 'Ensure access to legal aid & Prevent unnecessary and prolonged detention',
  PRE_TRIAL = 'The supervision programs of the precautionary measures in liberty count with sufficient resources and materials to operate. And the rational use of pre-trial detention',
  CHALLENGES = 'That the general public has a better understanding of the challenges of and solutions to reentry',
  YOUNG_PEOPLE = 'For less children and young people to become involved in the criminal justice system',
  DISPUTES = 'To mediate tiny disputes at local level',
  IMPROVE = 'Institution-wide implementation of policies to improve transparency, legitimacy and accessibility of court procedures and processes; including policies that ensure all court users are treated with dignity and respect.',
  ROMA = 'Institution-wide implementation of (Roma) Mentoring.',
  REDUCE_PRETRIAL = 'Reduce the number of people held in jail pretrial that can be safely supervised in the community.',
}

export const LONG_TERM_OUTCOMES = {
  filterName: 'longTermOutcomes',
  displayName: 'Long-term Outcomes',
  filterOptions: LongTermOutcomes,
};

export enum Activities {
  LEGAL = 'Legal Aid Services',
  MENTAL = 'Mental health, counseling services and clinical psychological treatment',
  HEALTH = 'Health and Medical Services',
  EDUCATION = 'Education programs',
  JOB = 'Job or vocational training programs',
  ADDICTIONS = 'Addictions treatment programs',
  Recreation = 'Recreation or sports programs',
  RELIGOUS = 'Religious programs',
  ARTS = 'Arts and cultural programs',
  CONFLICT = 'Conflict resolution or mediation services',
  RESTORATIVE = 'Restorative justice programs',
  PRISON = 'Prison staff capacity or accountability',
  DIVERSION = 'Diversion programs',
  COMMUNITY = 'Community service programs (for alternative sentences)',
  REINTEGRATION = 'Reintegration',
  POLICY = 'Policy development or research',
  PUBLIC = 'Public awareness, attitude change or education',
  ADVOCACY = 'Advocacy aimed at policymakers',
  WATCH = 'Watchdog organization',
  MEDIA = 'Media organization',
  BUILD = 'Institutional capacity-building: infrastructure, conditions, technology, services',
  STAFF = 'Prison staff capacity or accountability',
  TECH = 'Technology or innovative prison design',
}
export const ACTIVITIES = {
  filterName: 'typeOfActivity',
  displayName: 'Activities / Program Focus',
  filterOptions: Activities,
};

export enum AdvocacyTargetGroups {
  LOCAL_OFFICIALS = 'Local government officials',
  NATIONAL_OFFICIALS = 'National government officials',
  PRIVATE_SECTOR = 'Private sector',
  INTL_ORGS = 'International organizations',
  CORRECTIONS = 'Corrections institutions or leaders',
  CO = 'Corrections officers',
  NON_PROFIT = 'Nonprofit organizations',
  ELECTEDS = 'Elected officials',
  COMMUNITIES = 'Local communities',
  GEN_PUB = 'General public',
  LEADERS = 'Leaders',
  STAFF = 'Front-line staff',
  ST_GOV = 'State government',
  RET_POP = 'The returning population themselves',
}

export const ADVOCACY_TARGET_GROUPS = {
  filterName: 'advocacyTargetGroups',
  displayName: 'Advocacy Target Groups',
  filterOptions: 'AdvocacyTargetGroups',
};

export enum AreasOfPolicyInfluence {
  LEGISLATION = 'Legislation regulating prisons and jails',
  CODE = 'Criminal code or sentencing issues',
  DE_CRIMINAL = 'Drug de-criminalization (partial or full)',
  MEDICAL = 'Health and medical issues related to prisons',
  MENTAL = 'Mental health related to prisons',
  EDU = 'Education related to prisons',
  REENTRY_EMP = 'Reentry issues: employment',
  REENTRY_HOUSING = 'Reentry issues: Housing',
  DRUG_USE = 'Drug use and addictions: services, including harm reduction, related to prisons',
  GROUPS = 'Specific racial, ethnic, or cultural groups’ needs in the context of prisons',
  JUVENILE = 'Juvenile issues',
  LGBTQ = 'LGBTQ issues',
  PRE_TRIAL = 'Pretrial policies and services (including bail, pretrial detention, etc.)',
  CONFINEMENT = 'General conditions of confinement',
  SOLITARY = 'Solitary confinement',
  MEDIATION = 'Conflict mediation, restorative justice, etc.',
  STAFF_MISC = 'Reducing prison staff misconduct',
  STAFF_WELL = 'Prison staff well-being or working conditions',
  OTHER = 'Other',
}

export const AREAS_OF_POLICY_INFLUENCE = {
  filterName: 'areasOfPolicyInfluence',
  displayName: 'Areas of Policy Influence',
  filterOptions: AreasOfPolicyInfluence,
};

// ! Countries
export const Countries = [
  'Africa',
  'Argentina',
  'Australia',
  'Bangladesh',
  'Belgium',
  'Belize',
  'Benin',
  'Bolivia',
  'Brazil',
  'Cambodia',
  'Canada',
  'Chile',
  'China',
  'Colombia',
  'Costa Rica',
  'Croatia',
  'Czech Republic',
  'Denmark',
  'Dominican Republic',
  'Egypt',
  'El Salvador',
  'Ethiopia',
  'Europe',
  'Finland',
  'France',
  'Gabon',
  'Georgia',
  'Germany',
  'Ghana',
  'Global',
  'Greenland',
  'Hong Kong',
  'Hungary',
  'India',
  'Indonesia',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kenya',
  'Kyrgyzstan',
  'Latin America',
  'Lebanon',
  'Lesotho',
  'Malawi',
  'Mexico',
  'Moldova',
  'Morocco',
  'Myanmar',
  'New Zealand',
  'Nigeria',
  'Northern Ireland',
  'Norway',
  'Pakistan',
  'Palestine',
  'Panama',
  'Peru',
  'Philippines',
  'Poland',
  'Puerto Rico',
  'Republic of Cyprus',
  'Republic of Ireland',
  'Romania',
  'Scotland',
  'Sierra Leone',
  'Singapore',
  'South Africa',
  'South Korea',
  'Southern Africa',
  'Spain',
  'Sweden',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'The Netherlands',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Uganda',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Venezuela',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const COUNTRIES = {
  filterName: 'countryRegion',
  displayName: 'Country / Region',
  filterOptions: Countries,
};

export const FILTER_OPTIONS: ICheckboxConfig[] = [
  ACTIVITIES,
  ADVOCACY_TARGET_GROUPS,
  AREAS_OF_POLICY_INFLUENCE,
  COUNTRIES,
  EVALUATION_CONDUCTED,
  LONG_TERM_CHANGE,
  LONG_TERM_OUTCOMES,
  MAIN_BENEFICIARIES,
  PROGRAM_SUB_TYPES,
  PROGRAM_TYPES,
  SERVICES,
  TARGET_GROUPS,
];
