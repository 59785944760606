import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import s from './Map.module.scss';

import { mapStyles } from './map-styles';
import { MapPin } from './Pin/Pin';

import { IMapPin } from '../../interfaces/map-pin.interface';
import { MAP_PINS } from '../../utils/map-pins';
// import { createMapOptions } from './map-options';

interface IProps {
  activeId?: string;
  handlePinClick: (name: string) => void;
}

export const createMapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  scaleControl: false,
  rotateControl: false,
  zoomControl: false,
  draggable: true,
  styles: mapStyles,
  disableDefaultUI: true,
  scrollwheel: false,
  disableDoubleClickZoom: true,
};

export const Map = ({ handlePinClick }: IProps) => {
  const [pins] = React.useState(MAP_PINS);

  const renderPins = () => {
    return pins.map((pin: IMapPin) => (
      <MapPin
        key={pin.name}
        lat={pin.lat}
        lng={pin.lng}
        name={pin.name}
        onClick={() => handlePinClick(pin.name)}
      />
    ));
  };

  return (
    <div className={s.Map}>
      <GoogleMapReact
        options={createMapOptions}
        bootstrapURLKeys={{ key: 'AIzaSyAxsPjtYpJJtiFMtppPU40lipnzlJuPH74' }}
        defaultZoom={3}
        defaultCenter={{ lat: 25.32344, lng: -5.154467 }}
      >
        {renderPins()}
      </GoogleMapReact>
    </div>
  );
};
