import React from 'react';
import 'rc-slider/assets/index.css';
import classnames from 'classnames';
import { Formik } from 'formik';
import SEO from '../components/ui/Seo';
import LandingLayout from '../components/ui/Layout/LandingLayout';
import '../styles/screen.css';

import { Map } from '../components/Map/Map';
import { SearchBar } from '../components/Search/SearchBar';
import CountrySelect from '../components/Search/CountrySelect';

import {
  SEARCH_LIMIT,
  CONTENTFUL_CLIENT,
  QueryParams,
  ProgramTypes,
  ProgramTypeFilters,
  FilterSet,
} from '../utils/search/constants';
import ProgramCard from '../components/ui/ProgramCard/ProgramCard';
import {
  IProgramCard,
  IProgramResponse,
} from '../interfaces/program-card.interface';

import Masonry from 'react-masonry-component';
import SubtypesSearch from '../components/Search/Subtypes';
import DeepDive from '../components/Search/DeepDiveNew';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import BUGHERD from '../BUGHERD';
import PageSubTitle from '../components/ui/PageSubTitle/PageSubTitle';
import SearchForm from '../components/ui/SearchForm/SearchForm';
import SearchFormTab from '../components/ui/SearchFormTab/SearchFormTab';

import IconGlobe from '../svgs/ico_globe.svg';
import CloseX from '../svgs/close-x.svg';

const isClient = typeof window !== 'undefined';

interface IRange {
  min: number;
  max: number;
}

function isIRange(obj: any): obj is IRange {
  return (
    obj &&
    typeof obj &&
    ['min', 'max'].every(
      k => obj.hasOwnProperty(k) && typeof obj[k] === 'number'
    )
  );
}

export const CONTENTFUL_INITIAL_SEARCH = {
  skip: 0,
  limit: 50,
  include: 1,
  content_type: 'program',
};

const SearchPageTemplate = () => {
  const [results, setResults] = React.useState<IProgramCard[]>(
    {} as IProgramCard[]
  );
  const [resultsCount, setResultsCount] = React.useState<number>(0);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [currentQueryParams, setCurrentQueryParams] = React.useState<any>();
  const [filters, setFilters] = React.useState<FilterSet | null>(FilterSet.CT);

  const [countries, setCountries] = React.useState<string[]>([]);

  const [programTypeFilters, setProgramTypeFilters] = React.useState<
    ProgramTypeFilters
  >({
    programSubType: [],
    programType: [],
  });

  const [beneficiaryFilters, setBeneficiaryFilters] = React.useState<string[]>(
    []
  );
  const [targetGroupFilters, setTargetGroupFilters] = React.useState<string[]>(
    []
  );
  const [typeOfActivityFilters, setTypeOfActivityFilters] = React.useState<
    string[]
  >([]);
  const [yearsInOperationFilters, setYearsInOperationFilters] = React.useState<{
    min: number;
    max: number;
  }>({ min: 0, max: 0 });
  const [participantsFilters, setParticipantsFilters] = React.useState<{
    min: number;
    max: number;
  }>({ min: 0, max: 0 });
  const [budgetFilters, setBudgetFilters] = React.useState<{
    min: number;
    max: number;
  }>({ min: 0, max: 0 });
  const [
    evaluationConductedFilters,
    setEvaluationConductedFilters,
  ] = React.useState<string[]>([]);

  const clearAllFilters = () => {
    setProgramTypeFilters({
      programSubType: [],
      programType: [],
    });
    setBeneficiaryFilters([]);
    setTargetGroupFilters([]);
    setTypeOfActivityFilters([]);
    setYearsInOperationFilters({ min: 0, max: 0 });
    setBudgetFilters({ min: 0, max: 0 });
    setCountries([]);
    setEvaluationConductedFilters([]);
  };

  const noFiltersShowing =
    countries.length === 0 &&
    programTypeFilters.programSubType.length === 0 &&
    programTypeFilters.programType.length === 0 &&
    beneficiaryFilters.length === 0 &&
    targetGroupFilters.length === 0 &&
    typeOfActivityFilters.length === 0 &&
    yearsInOperationFilters.max === 0 &&
    participantsFilters.max === 0 &&
    budgetFilters.max === 0 &&
    evaluationConductedFilters === null;

  // UI
  const [isMapShowing, setIsMapShowing] = React.useState(true);
  const [isCheckboxShowing] = React.useState(false);

  const appendObjects = (arr: any[] = [], objs: any[] = []): any[] => {
    if (objs.length) {
      const res = [objs, ...arr].filter(o => o !== undefined);
      return [].concat(...res);
    } else {
      return arr;
    }
  };

  React.useEffect(() => {
    fetchPrograms(CONTENTFUL_INITIAL_SEARCH);
    setIsMapShowing(true);
  }, []);

  const fetchPrograms = async (
    query: any,
    filter?: (p: IProgramCard, i: number) => boolean
  ) => {
    // console.log('fetching programs', query);
    const newResults: IProgramCard[] = [];
    const fields: string[] = [];

    // const globalBeneficiaries = new Set<string>();
    // const globalObjectives = new Set<string>();
    // const globalLongTermChange = new Set<string>();

    setCurrentQueryParams(query);
    CONTENTFUL_CLIENT.getEntries(query)
      .then((programResponse: any) => {
        setResultsCount(programResponse.total);
        programResponse.items.forEach(async (prg: any) => {
          // console.log('program', prg.fields);
          const {
            sys: { id },
            fields: {
              countryRegion,
              programType,
              programName,
              programSubType,
              website,
              imageLinks,
              questionnaires,
              yearsInOperation,
              targetGroup,
              typeOfActivity,
            },
          }: IProgramResponse = prg;
          // for (let key in prg.fields) {
          //   if (!fields.includes(key)) {
          //     fields.push(key);
          //   }
          // }
          let subtype;
          for (let key of [
            'policyAdvocacyDd',
            'directServiceDd',
            'altPrisonDd',
            'innovativeAlternativeDd',
          ]) {
            if (prg.fields[key]) {
              subtype = key;
              break;
            }
          }
          // subtype &&
          //   prg.fields[subtype] &&
          //   console.log(prg.fields[subtype].fields);
          const evaluationConducted =
            subtype && prg.fields[subtype]
              ? prg.fields[subtype].fields.typeOfEvaluationsConducted
              : null;
          let budget =
            subtype && prg.fields[subtype]
              ? prg.fields[subtype].fields.annualProgramBudget
              : null;
          if (budget) budget = +budget.trim().replace(/[,\$]/g, '');
          const participants =
            subtype && prg.fields[subtype]
              ? prg.fields[subtype].fields.participantsbeneficiariesIn2017
              : null;
          if (questionnaires) {
            Object.keys(prg.fields).forEach(field => {
              if (!fields[field]) fields[field] = [];
              fields[field].push(prg.fields[field]);
            });
          }
          const beneficiaries =
            subtype && prg.fields[subtype]
              ? prg.fields[subtype].fields.mainBeneficiaries
              : [];
          let prgCard: IProgramCard = {
            beneficiaries,
            budget,
            countryRegion,
            evaluationConducted,
            participants,
            programType,
            programName,
            programSubType,
            website,
            imageLinks,
            id,
            yearsInOperation,
            targetGroup,
            typeOfActivity,
          };

          // console.log('all fields', fields);

          if (questionnaires && programResponse.includes.Entry.length > 0) {
            let ques = programResponse.includes.Entry.filter((q: any) => {
              return questionnaires[0].sys.id === q.sys.id;
            });

            prgCard.slug = ques[0] && ques[0].fields.slug;
          }
          newResults.push(prgCard);
        });
        // console.log('NEW RESULTS', newResults.map(r => r.evaluationConducted));
        // console.log({ globalLongTermChange });
        return newResults;
      })
      .then(() => {
        // assume that we never need pagination for INN Partner search
        // since there are comparatively fewer and we search for more at a time
        if (pageIndex >= 1) {
          setResults(appendObjects(newResults, results));
        } else {
          const filtered = filter ? newResults.filter(filter) : newResults;
          setResults(filtered);
          if (filter) setResultsCount(filtered.length);
        }
      });
  };

  React.useEffect(() => {
    if (pageIndex > 0) {
      handleFetchMore();
    }
  }, [pageIndex]);

  // TODO: hide fetch more if no more results
  const handleFetchMore = () => {
    let skipAmount = pageIndex * parseInt(SEARCH_LIMIT);
    let update = currentQueryParams;
    update.skip = skipAmount;

    fetchPrograms(update);
  };

  const handleIncrementPage = () => {
    setPageIndex(prev => prev + 1);
  };

  const handleToggleFilters = (f: FilterSet) => (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (filters === f) {
      setFilters(null);
    } else {
      setFilters(f);
      setIsMapShowing(false);
    }
  };

  return (
    <LandingLayout>
      <div dangerouslySetInnerHTML={{ __html: BUGHERD }} />
      <main className={classnames({ ['filters-toggle']: filters !== null })}>
        <SEO title={'Incarceration Nations'} />

        <Formik
          initialValues={{
            typeOfActivity: [],
            advocacyTargetGroups: [],
            areasOfPolicyInfluence: [],
            countryRegion: [],
            evaluationConducted: '',
            longTermChange: [],
            longTermOutcomes: [],
            mainBeneficiaries: [],
            query: '',
            programSubType: [],
            programType: [ProgramTypes.AD, ProgramTypes.ALT, ProgramTypes.DS],
            services: [],
            targetGroups: [],
            typeOfEvaluationsConducted: [],
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isSubmitting,
            setFieldValue,
            initialValues,
            /* and other goodies */
          }) => {
            const updateByProgramSubType = () => {
              let queryParams = {
                skip: 0,
                limit: SEARCH_LIMIT,
                include: 1,
                content_type: 'program',
              };

              const pt = programTypeFilters.programType;
              const st = programTypeFilters.programSubType;

              if (pt.length === 0 || st.length === 0) {
                handleReset();
                handleSearch();
                return;
              }

              queryParams['fields.programType[in]'] = pt.toString();
              queryParams['fields.programSubType[in]'] = st.toString();
              fetchPrograms(queryParams);
            };

            const updateByINNPartner = () => {
              let queryParams = {
                skip: 0,
                limit: 200,
                include: 1,
                content_type: 'program',
                'fields.questionnaires[exists]': 'true',
              };

              fetchPrograms(
                queryParams,
                (p: IProgramCard, i: number): boolean => {
                  return [
                    { filters: targetGroupFilters, key: 'targetGroup' },
                    {
                      filters: typeOfActivityFilters,
                      key: 'typeOfActivity',
                    },
                    {
                      filters: yearsInOperationFilters,
                      key: 'yearsInOperation',
                    },
                    {
                      filters: evaluationConductedFilters,
                      key: 'typeOfEvaluationsConducted',
                    },
                    {
                      filters: budgetFilters,
                      key: 'budget',
                    },
                    {
                      filters: participantsFilters,
                      key: 'participants',
                    },
                    {
                      filters: beneficiaryFilters,
                      key: 'beneficiaries',
                    },
                    {
                      filters: programTypeFilters,
                      key: 'programSubType',
                    },
                  ].every(({ filters, key }) => {
                    // types that are IRange
                    if (
                      ['yearsInOperation', 'budget', 'participants'].includes(
                        key
                      )
                    ) {
                      if (!isIRange(filters)) {
                        console.error('Error handling range filters.');
                        return false;
                      }
                      if (filters.max === 0) return true;
                      const { min, max } = filters;
                      return (
                        p[key] !== undefined && p[key] >= min && p[key] <= max
                      );

                      // types that are string[]
                    } else if (key === 'typeOfActivity') {
                      if (typeOfActivityFilters.length === 0) return true;
                      return p.typeOfActivity
                        ? p.typeOfActivity.some(b =>
                            typeOfActivityFilters.includes(b)
                          )
                        : false;
                    } else if (key === 'typeOfEvaluationsConducted') {
                      if (evaluationConductedFilters.length === 0) return true;
                      console.log(
                        'type of eval conducted',
                        p.evaluationConducted
                      );
                      return p.evaluationConducted
                        ? p.evaluationConducted.some(b =>
                            evaluationConductedFilters.includes(b)
                          )
                        : false;
                    } else if (key === 'beneficiaries') {
                      if (beneficiaryFilters.length === 0) return true;
                      return p.beneficiaries
                        ? p.beneficiaries.some(b =>
                            beneficiaryFilters.includes(b)
                          )
                        : false;
                      // types that are boolean
                    } else if (key === 'programSubType') {
                      if (programTypeFilters.programSubType.length === 0)
                        return true;
                      if (!p.programSubType) return false;
                      return programTypeFilters.programSubType.includes(
                        p.programSubType
                      );
                      // @ts-ignore
                    } else if (filters.length === 0) {
                      return true;
                    }

                    return (
                      p[key] &&
                      // @ts-ignore
                      filters.includes(p[key])
                    );
                  });
                }
              );
            };

            const handleSearch = () => {
              setIsMapShowing(false);
              let queryParams = {
                skip: 0,
                limit: SEARCH_LIMIT,
                include: 1,
                content_type: 'program',
              };

              let valueKeys = Object.keys(values);
              let searchValues = valueKeys.filter(k => values[k].length > 0);

              // k is the id that we need from the query enum and the value object.
              // this will construct the query
              searchValues.forEach((searchValue: any) => {
                let qp = QueryParams[`${searchValue}`];
                let value = values[`${searchValue}`];
                if (Array.isArray(value)) {
                  queryParams[`${qp}`] = value.toString();
                } else {
                  queryParams[`${qp}`] = value;
                }
              });
              // console.log('fetching programs:', queryParams);
              fetchPrograms(queryParams);
            };

            const handleShowResults = () => {
              setFilters(null);
              handleSearch();
            };

            const searchByCountry = (name?: string) => {
              let queryParams = {
                skip: 0,
                limit: SEARCH_LIMIT,
                include: 1,
                content_type: 'program',
              };

              if (name) {
                queryParams[QueryParams.countryRegionMatch] = name;
              } else {
                queryParams[QueryParams.countryRegionIn] = countries.toString();
              }

              console.log(queryParams);

              fetchPrograms(queryParams);
              setIsMapShowing(false);
            };

            return (
              <>
                <PageTitle>
                  Prison Reform &amp; Reimagining Programs Worldwide
                </PageTitle>
                <PageSubTitle>
                  Use this platform to search for programs by country (click on
                  the map), by program type, by{' '}
                  <a
                    onClick={() => {
                      setIsMapShowing(false);
                      setFilters(FilterSet.DD);
                      updateByINNPartner();
                    }}
                  >
                    INN Partners
                  </a>
                  , or keyword (search bar), or using the search tools to
                  identify specific types&nbsp;of&nbsp;programs.
                </PageSubTitle>
                <SearchForm>
                  <div>
                    <SearchFormTab noHover style={{ textDecoration: 'none' }}>
                      {resultsCount} Result{resultsCount !== 1 && 's'}
                    </SearchFormTab>
                    <SearchFormTab
                      active={isMapShowing}
                      onClick={() => {
                        handleReset();
                        setIsMapShowing(true);
                        setFilters(FilterSet.CT);
                      }}
                    >
                      Search by Country{' '}
                      <IconGlobe
                        height={24}
                        width={24}
                        style={{
                          marginLeft: 12,
                          fill: isMapShowing ? '#ffffff' : '#deb406',
                          // stroke: 'none',
                        }}
                      />
                    </SearchFormTab>
                    <SearchFormTab
                      active={filters === FilterSet.ST}
                      onClick={handleToggleFilters(FilterSet.ST)}
                    >
                      Search by Program Type
                    </SearchFormTab>
                    <SearchFormTab
                      active={filters === FilterSet.DD}
                      onClick={handleToggleFilters(FilterSet.DD)}
                    >
                      Search INN Partners
                    </SearchFormTab>
                  </div>
                  <div>
                    <SearchBar onClick={handleSearch} />
                    {!noFiltersShowing && (
                      <SearchFormTab
                        noBorder
                        onClick={() => {
                          handleReset();
                          setFilters(null);
                          clearAllFilters();
                          handleSearch();
                        }}
                      >
                        <CloseX
                          width={13}
                          height={13}
                          stroke="#fff"
                          style={{ marginRight: 12 }}
                        />
                        Clear Filters
                      </SearchFormTab>
                    )}
                  </div>
                </SearchForm>
                {filters !== null && (
                  <form
                    onSubmit={handleSubmit}
                    className={classnames('form-filters', {
                      ['dropdown-active']: isCheckboxShowing,
                    })}
                    style={{ marginBottom: 0, zIndex: 900 }}
                    id={'filters'}
                  >
                    <div className={'triple subtypetriple'}>
                      {filters === FilterSet.ST ? (
                        <SubtypesSearch
                          programTypeFilters={programTypeFilters}
                          setProgramTypeFilters={setProgramTypeFilters}
                          handleShowResults={handleShowResults}
                          update={updateByProgramSubType}
                          hide={() => setFilters(null)}
                        />
                      ) : filters === FilterSet.DD ? (
                        <DeepDive
                          update={updateByINNPartner}
                          hide={() => setFilters(null)}
                          beneficiaryFilters={beneficiaryFilters}
                          setBeneficiaryFilters={setBeneficiaryFilters}
                          targetGroupFilters={targetGroupFilters}
                          setTargetGroupFilters={setTargetGroupFilters}
                          typeOfActivityFilters={typeOfActivityFilters}
                          setTypeOfActivityFilters={setTypeOfActivityFilters}
                          yearsInOperationFilters={yearsInOperationFilters}
                          setYearsInOperationFilters={
                            setYearsInOperationFilters
                          }
                          participantsFilters={participantsFilters}
                          setParticipantsFilters={setParticipantsFilters}
                          evaluationConductedFilters={
                            evaluationConductedFilters
                          }
                          setEvaluationConductedFilters={
                            setEvaluationConductedFilters
                          }
                          budgetFilters={budgetFilters}
                          setBudgetFilters={setBudgetFilters}
                          programTypeFilters={programTypeFilters}
                          setProgramTypeFilters={setProgramTypeFilters}
                        />
                      ) : (
                        <CountrySelect
                          hide={() => setFilters(null)}
                          update={() => searchByCountry()}
                          countries={countries}
                          setCountries={setCountries}
                        />
                      )}
                    </div>
                  </form>
                )}
                <article
                  className={classnames('module-index', {
                    ['has-map']: isMapShowing,
                  })}
                >
                  <div>
                    {!isMapShowing && (
                      <Masonry
                        className={'list-index'} // default ''
                        elementType={'ul'} // default 'div'
                        disableImagesLoaded={false} // default false
                        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                      >
                        {results &&
                          results.length > 0 &&
                          results.map((res: IProgramCard) => (
                            <ProgramCard key={res.id} programCard={res} />
                          ))}
                      </Masonry>
                    )}

                    <p
                      className={classnames('link-btn wide', 'text-center', {
                        ['hidden']: isMapShowing,
                      })}
                    >
                      {resultsCount >
                      (pageIndex + 1) * parseInt(SEARCH_LIMIT) ? (
                        <a onClick={handleIncrementPage}>See more programs</a>
                      ) : (
                        <a>End of Results</a>
                      )}
                    </p>
                    {isMapShowing && (
                      <>
                        <div id="map">
                          {isClient && <Map handlePinClick={searchByCountry} />}
                        </div>
                      </>
                    )}
                  </div>
                </article>
              </>
            );
          }}
        </Formik>
      </main>
    </LandingLayout>
  );
};

export default SearchPageTemplate;
