import * as React from 'react';
import Yellow from '../../../svgs/yellow-pin.svg';

export const MapPin = ({
  lat,
  lng,
  name,
  onClick,
}: {
  lat: number;
  lng: number;
  name: string;
  onClick: (id: string) => void;
}) => {
  return (
    <>
      <div
        onClick={() => onClick(name)}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          transform: 'translate(-8.5px, -11px)',
        }}
      >
        <Yellow lat={lat} lng={lng}></Yellow>
      </div>
    </>
  );
};

export default MapPin;
