import React from 'react';
import { uniq } from 'lodash';
import { Field } from 'formik';
import 'rc-slider/assets/index.css';
import {
  ACTIVITIES,
  TARGET_GROUPS,
  MAIN_BENEFICIARIES,
  ProgramTypeFilters,
  ALT_SUB_TYPES,
  AD_SUB_TYPES,
  DS_SUB_TYPES,
  ProgramTypes,
  EVALUATION_CONDUCTED,
} from '../../utils/search/constants';
import s from './Search.module.scss';
import MockSelect from './MockSelect';
import IconSearch from '../../svgs/ico_search.svg';
import { MockOptionsType } from './Subtypes';

export const MockOptions = ({
  options,
  onChange,
  selected,
}: {
  options: string[];
  selected: string[];
  onChange: Function;
}) => {
  const viewingAll =
    selected.filter(s => options.includes(s)).length === options.length;
  return (
    <div className={s.MockOptions}>
      <div
        className={s.MockOption}
        onClick={e => {
          e.stopPropagation();
          if (viewingAll) {
            onChange(selected.filter(s => !options.includes(s)));
          } else {
            onChange(uniq(selected.concat(options)));
          }
        }}
      >
        <Field type="checkbox" checked={viewingAll} />
        View All
      </div>
      {options.map((name: string) => {
        const checked = selected ? selected.includes(name) : false;
        return (
          <div key={name}>
            <div
              className={s.MockOption}
              onClick={e => {
                e.stopPropagation();
                if (checked) {
                  onChange(selected.filter(s => s !== name));
                } else {
                  onChange(selected.concat(name));
                }
              }}
            >
              <Field type="checkbox" checked={checked} />
              {name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const MockOptionsRange = ({
  options,
  onChange,
  selected,
}: {
  options: { name: string; min: number; max: number }[];
  selected: { min: number; max: number };
  onChange: Function;
}) => {
  return (
    <div className={s.MockOptions}>
      {options.map(({ name, min, max }) => {
        const checked = selected
          ? selected.min === min && selected.max === max
          : false;
        return (
          <div key={name}>
            <div
              className={s.MockOption}
              onClick={e => {
                e.stopPropagation();
                if (checked) {
                  onChange({ min: 0, max: 0 });
                } else {
                  onChange({ min, max });
                }
              }}
            >
              <Field type="checkbox" checked={checked} />
              {name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface IProps {
  hide: () => void;
  update: () => void;
  beneficiaryFilters: string[];
  setBeneficiaryFilters: React.Dispatch<React.SetStateAction<string[]>>;
  targetGroupFilters: string[];
  setTargetGroupFilters: React.Dispatch<React.SetStateAction<string[]>>;
  programTypeFilters: ProgramTypeFilters;
  setProgramTypeFilters: React.Dispatch<
    React.SetStateAction<ProgramTypeFilters>
  >;
  typeOfActivityFilters: string[];
  setTypeOfActivityFilters: React.Dispatch<React.SetStateAction<string[]>>;
  yearsInOperationFilters: { min: number; max: number };
  setYearsInOperationFilters: React.Dispatch<
    React.SetStateAction<{ min: number; max: number }>
  >;
  budgetFilters: { min: number; max: number };
  setBudgetFilters: React.Dispatch<
    React.SetStateAction<{ min: number; max: number }>
  >;
  participantsFilters: { min: number; max: number };
  setParticipantsFilters: React.Dispatch<
    React.SetStateAction<{ min: number; max: number }>
  >;
  evaluationConductedFilters: string[];
  setEvaluationConductedFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const DeepDive = ({
  hide,
  update,
  beneficiaryFilters,
  setBeneficiaryFilters,
  targetGroupFilters,
  setTargetGroupFilters,
  typeOfActivityFilters,
  setTypeOfActivityFilters,
  yearsInOperationFilters,
  setYearsInOperationFilters,
  budgetFilters,
  setBudgetFilters,
  participantsFilters,
  setParticipantsFilters,
  evaluationConductedFilters,
  setEvaluationConductedFilters,
  programTypeFilters,
  setProgramTypeFilters,
}: IProps) => {
  const selectedAlt = programTypeFilters.programSubType.filter(s =>
    Object.values(ALT_SUB_TYPES.filterOptions).includes(s)
  ).length;
  const selectedAdvo = programTypeFilters.programSubType.filter(s =>
    Object.values(AD_SUB_TYPES.filterOptions).includes(s)
  ).length;
  const selectedDirect = programTypeFilters.programSubType.filter(s =>
    Object.values(DS_SUB_TYPES.filterOptions).includes(s)
  ).length;

  const yearsImplemented = [
    { name: 'Less than 2 years', min: 0, max: 2 },
    { name: '3-5 years', min: 3, max: 5 },
    { name: '6-10 years', min: 6, max: 10 },
    { name: '11-50 years', min: 11, max: 50 },
    { name: '51-100 years', min: 51, max: 100 },
    { name: 'More than 100 years', min: 100, max: Infinity },
  ];

  const budget = [
    { name: 'Less than $10,000', min: 0, max: 9999 },
    { name: '$10,000 - $99,999', min: 10000, max: 99999 },
    { name: '$100,000 - $499,999', min: 100000, max: 499999 },
    { name: '$500,000 - $1,000,000', min: 500000, max: 1000000 },
    { name: 'More than $1,000,000', min: 1000001, max: Infinity },
  ];

  const participants = [
    { name: 'Less than 100', min: 0, max: 99 },
    { name: '100-499', min: 100, max: 499 },
    { name: '500-999', min: 500, max: 999 },
    { name: '1,000-4,999', min: 1000, max: 4999 },
    { name: '5,000-10,000', min: 5000, max: 10000 },
    { name: 'More than 10,000', min: 10000, max: Infinity },
  ];

  return (
    <>
      <div className={s.DeepDiveContainer}>
        <div>
          <MockSelect>
            Alternatives to Incarceration{' '}
            {selectedAlt > 0 && `(${selectedAlt})`}
          </MockSelect>
          <MockOptionsType
            programTypeFilters={programTypeFilters}
            options={Object.values(ALT_SUB_TYPES.filterOptions)}
            setProgramTypeFilters={setProgramTypeFilters}
            type={ProgramTypes.ALT}
          />
        </div>
        <div>
          <MockSelect>
            Advocacy {selectedAdvo > 0 && `(${selectedAdvo})`}
          </MockSelect>
          <MockOptionsType
            programTypeFilters={programTypeFilters}
            options={Object.values(AD_SUB_TYPES.filterOptions)}
            setProgramTypeFilters={setProgramTypeFilters}
            type={ProgramTypes.AD}
          />
        </div>
        <div>
          <MockSelect>
            Direct Services for Incarcerated or Formerly Incarcerated{' '}
            {selectedDirect > 0 && `(${selectedDirect})`}
          </MockSelect>
          <MockOptionsType
            programTypeFilters={programTypeFilters}
            options={Object.values(DS_SUB_TYPES.filterOptions)}
            setProgramTypeFilters={setProgramTypeFilters}
            type={ProgramTypes.DS}
          />
        </div>
      </div>
      <div className={s.DeepDiveContainer}>
        <div>
          <MockSelect>
            Main beneficiaries{' '}
            {beneficiaryFilters.length > 0 && `(${beneficiaryFilters.length})`}
          </MockSelect>
          <MockOptions
            selected={beneficiaryFilters}
            onChange={setBeneficiaryFilters}
            options={Object.values(MAIN_BENEFICIARIES.filterOptions)}
          />
        </div>
        <div>
          <MockSelect>
            Target groups{' '}
            {targetGroupFilters.length > 0 && `(${targetGroupFilters.length})`}
          </MockSelect>
          <MockOptions
            selected={targetGroupFilters}
            onChange={setTargetGroupFilters}
            options={Object.values(TARGET_GROUPS.filterOptions)}
          />
        </div>
        {/* <div>
          <MockSelect>Objectives</MockSelect>
        </div> */}
        <div>
          <MockSelect>
            Activities/program focus{' '}
            {typeOfActivityFilters.length > 0 &&
              `(${typeOfActivityFilters.length})`}
          </MockSelect>
          <MockOptions
            selected={typeOfActivityFilters}
            onChange={setTypeOfActivityFilters}
            options={Object.values(ACTIVITIES.filterOptions)}
          />
        </div>
      </div>
      <div className={s.DeepDiveContainer}>
        <div>
          <MockSelect>
            Years implemented{' '}
            {yearsInOperationFilters.max > 0 &&
              `(${
                // @ts-ignore
                yearsImplemented.find(
                  y =>
                    y.min === yearsInOperationFilters.min &&
                    y.max === yearsInOperationFilters.max
                ).name
              })`}
          </MockSelect>
          <MockOptionsRange
            options={yearsImplemented}
            onChange={setYearsInOperationFilters}
            selected={yearsInOperationFilters}
          />
        </div>
        <div>
          <MockSelect>
            Annual Budget{' '}
            {budgetFilters.max > 0 &&
              `(${
                // @ts-ignore
                budget.find(
                  b =>
                    b.min === budgetFilters.min && b.max === budgetFilters.max
                ).name
              })`}
          </MockSelect>
          <MockOptionsRange
            options={budget}
            onChange={setBudgetFilters}
            selected={budgetFilters}
          />
        </div>
        <div>
          <MockSelect>
            Number of Participants{' '}
            {participantsFilters.max > 0 &&
              `(${
                // @ts-ignore
                participants.find(
                  b =>
                    b.min === participantsFilters.min &&
                    b.max === participantsFilters.max
                ).name
              })`}
          </MockSelect>
          <MockOptionsRange
            options={participants}
            onChange={setParticipantsFilters}
            selected={participantsFilters}
          />
        </div>
        <div>
          <MockSelect>
            Evaluations Conducted{' '}
            {evaluationConductedFilters.length > 0 &&
              `(${evaluationConductedFilters.length})`}
          </MockSelect>
          <MockOptions
            selected={evaluationConductedFilters}
            onChange={setEvaluationConductedFilters}
            options={Object.values(EVALUATION_CONDUCTED.filterOptions)}
          />
        </div>
      </div>
      <button
        className={s.ApplyFilters}
        onClick={e => {
          e.preventDefault();
          update();
          hide();
        }}
        style={{ borderWidth: 0 }}
      >
        Show all INN Partners{' '}
        <IconSearch
          width={16}
          height={16}
          style={{
            marginLeft: 12,
            marginRight: -8,
            stroke: '#fff',
          }}
        />
      </button>
    </>
  );
};

export default DeepDive;
