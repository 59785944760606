import React, { MouseEvent } from 'react';
import { Field } from 'formik';
import s from './SearchBar.module.scss';

// Search component
export const SearchBar = ({
  searchTerm,
  onClick,
}: {
  searchTerm?: (term: string) => void;
  onClick: () => void;
}) => {
  const handleSearch = (e: MouseEvent) => {
    e.preventDefault();
    onClick();
  };
  return (
    <>
      {/* <label>Search</label> */}
      <Field
        className={s.SearchBar}
        type="text"
        name="query"
        placeholder="Enter keyword..."
      ></Field>
      <button onClick={e => handleSearch(e)}>Search</button>
    </>
  );
};
