import React from 'react';
import classnames from 'classnames';

import '../../../styles/SearchPageTemplate.css';
import s from './ProgramCard.module.scss';
import * as DSIcon from '../../../svgs/direct-service.svg';
import * as ADIcon from '../../../svgs/advocacy.svg';
import * as IAIcon from '../../../svgs/alternative-to-incarceration.svg';

import { Link } from 'gatsby';
import { ProgramTypes } from '../../../utils/search/constants';
import { IProgramCard } from '../../../interfaces/program-card.interface';

const ProgramCard = ({
  programCard: {
    countryRegion,
    programType,
    programName,
    programSubType,
    website,
    imageLinks,
    slug,
  },
}: {
  programCard: IProgramCard;
}) => {
  // const imageStyle = { height: '125px;' };

  const [imageSrc, setImageSrc] = React.useState<string>();
  const [imageFail, setImageFail] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (imageLinks && imageLinks.length > 0) {
      setImageSrc(imageLinks[0]);
    }
  }, []);

  const renderIcon = () => {
    let icon;
    switch (programType) {
      case ProgramTypes.DS:
        icon = <DSIcon style={{ marginRight: '16px' }} />;
        break;
      case ProgramTypes.AD:
        icon = <ADIcon style={{ marginRight: '16px' }} />;
        break;
      default:
        icon = <IAIcon style={{ marginRight: '16px' }} />;
        break;
    }
    return icon;
  };

  const handleImgError = () => {
    setImageFail(true);
  };

  return (
    <>
      <li
        className={'mason'}
        style={{ display: 'flex', boxShadow: 'rgba(0, 0, 0, 0.1)' }}
      >
        <Link
          className={s.noPseudo}
          style={{ margin: '-16px -16px 0 -16px', padding: '16px 16px 0 16px' }}
          to={`/${slug}`}
        >
          <h2>
            <span className={'small'}>
              {renderIcon()}
              {programType}
            </span>{' '}
            {slug && <span className={'label'}>INN Partner</span>} {programName}
          </h2>

          {imageLinks && imageLinks.length > 0 && (
            <figure
              className={classnames({
                [s.hideImg]: imageFail,
                [s.showImg]: !imageFail,
              })}
            >
              <img
                className={classnames({
                  ['hidden']: imageFail,
                  [s.show]: !imageFail,
                })}
                style={{
                  maxHeight: '125px',
                  minHeight: '125px',
                }}
                src={imageSrc}
                width={'320'}
                onError={() => handleImgError()}
                height={'125'}
              />
            </figure>
          )}
          <p style={{ color: '#414042' }}>
            {programSubType} <span>{countryRegion}</span>
          </p>
        </Link>

        <a
          className={classnames({ [s.noPseudo]: slug !== undefined })}
          href={website}
          rel={'external'}
        >
          <p className={'text-right'}>
            {website &&
              website.replace(
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?/,
                ''
              )}
          </p>
        </a>
      </li>
    </>
  );
};

export default ProgramCard;
